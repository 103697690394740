<template>
  <div class="content-right rounded-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'personal-dashboard' }"
            >Dashboard</router-link
          >
        </li>
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'my-attendances' }"
            >My Attendances</router-link
          >
        </li>
        <li class="breadcrumb-item active">
          {{ filterString.replace(/^./, filterString[0].toUpperCase()) }}
        </li>
      </ol>
    </nav>
    <div
      class="content-body"
      id="fullHeight"
      style="min-height: calc(100vh - 135px)"
    >
      <div class="row">
        <div class="col-md-11">
          <h2 class="title2">
            {{ filterString.replace(/^./, filterString[0].toUpperCase()) }}
          </h2>
        </div>
        <div class="col-md-1 text-right"></div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div v-if="this.$store.state.loader">
            <loader
              object="#f74b3d"
              color1="#ffffff"
              color2="#17fd3d"
              size="5"
              speed="2"
              bg="#343a40"
              objectbg="#999793"
              opacity="80"
              disableScrolling="false"
              name="spinning"
            ></loader>
          </div>
          <div v-else class="card">
            <div class="card-header">
              <flash-message class="myCustomClass"></flash-message>
            </div>
            <div class="card-body">
              <b-pagination
                v-model="currentPage"
                :total-rows="myFilteredAttendancesData.length"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>
              <b-table
                class="table table-custom"
                :items="myFilteredAttendancesData"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
                show-empty
                sticky-header
              >
                <template #head()="{ label, field: { key, sortable } }">
                  {{ label }}
                  <template v-if="sortable">
                    <template>
                      <b-img
                        v-if="sortBy !== key"
                        :src="sortIconNeutral"
                        style="width: 0.65rem; height: 1rem"
                      ></b-img>
                      <b-img
                        v-else-if="sortDesc"
                        :src="sortIconAsc"
                        style="width: 0.65rem; height: 1rem"
                      ></b-img>
                      <b-img
                        v-else
                        :src="sortIconDesc"
                        style="width: 0.65rem; height: 1rem"
                      ></b-img>
                    </template>
                  </template>
                </template>
                <template v-slot:cell(status)="data">
                  {{ data.item.status_timein }}/ {{ data.item.status_timeout }}
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      filterString: this.$route.params.filterString,
      currentPage: 1,
      perPage: 15,
      sortIconNeutral:
        "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' opacity='.3' d='M51 1l25 23 24 22H1l25-22zM51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e",
      sortIconDesc:
        "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='black' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e",
      sortIconAsc:
        "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='black' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e",
      sortBy: null,
      sortDesc: false,
      fields: [
        {
          key: "date",
          sortable: true,
        },
        {
          key: "timein",
          label: "Time In",
        },
        {
          key: "timeout",
          label: "Time Out",
        },
        {
          key: "totalhours",
          label: "Total Hours",
        },
        {
          key: "status",
          label: "Status(In/Out)",
        },
      ],
    };
  },
  computed: {
    myFilteredAttendancesData() {
      return this.$store.state.myFilteredAttendancesData;
    },
  },
  created() {
    var token = localStorage.getItem("token");
    if (token == "" || token == null) {
      window.location.href = "/login";
    }
  },
  mounted() {
    this.$store.dispatch("switchLoader", true);
    console.log(this.filterString);
    this.$store.dispatch("loadMyFilteredAttendances", this.filterString);
    console.log(this.$store.state.myFilteredAttendancesData);
  },
  methods: {},
};
</script>
